#main__2sections{
   
    height: max-content;
    }
    
    #hero{
        height: calc(100vh - 56px);
        /* padding: 3rem 0; */
        display: flex;
        align-items: center;
    }
    #hero .__main{
        display: flex;
        flex-direction: column;
        row-gap: 90px;
    }
    .__content h1{
        font-size: 4rem;
        font-weight: 500;
    }
    .__content h1 .__name{
        font-size: 4.5rem;
        font-weight: 500;
    }
    
    .__img_box{
        /* width: 420px;
        height: 520px; */
        width: fit-content;
        height: fit-content;
        background-color: var(--main-color);
        /* box-shadow: 5px 7px 9px 3px rgba(0,0,0,0.17); */
        box-shadow: var(--main-shadow);
        /* align-items: center; */
        border-radius: 12px;
        position: relative;
        display: flex;
        justify-content: center;
    }
    .__img_box img{
    
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
        
     
    }
    
    .__stats .box{
        background-color: #c8e1ff;
        width: 170px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    /* ==========================
      =About me=
       ========================== */
    .main__about{
        position: relative;
        bottom: -3rem;
    }
       .about{
        /* width: 80vw; */
       
        /* height: 200px; */
        /* width: inherit; */
        width: inherit;
        background-color: var(--main-color);
        /* box-shadow: 5px 7px 9px 3px rgba(0,0,0,0.17); */
        box-shadow: var(--main-shadow);
        padding: 3rem 1rem ;
     
        /* background-color: red; */
       }
    
       .social__icons{
        position: absolute;
      background-color: var(--main-color);
      width: fit-content;
      padding: 1rem;
      display: flex;
      gap: 20px;
      bottom: -25px;
    
      /* left: 0;
      right: 0; */
      
      /* box-shadow: 5px 7px 9px 3px rgba(0,0,0,0.17); */
      box-shadow: var(--main-shadow);
       }
       .__icons{
        width: 30px;
        cursor: pointer;
       }
       /* .__icons:hover{
        fill: red;
       } */
    