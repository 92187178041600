@media screen and (width<=992px) {
    /* 
    ==================================
    hero section
    ==================================
     */
    #hero{
        height:100%;
        /* padding: 3rem 0; */
        padding: 1rem 0;
     
    }
  
    .__content h1{
        font-size: 3rem;
        font-weight: 500;
    }
    .__content h1 .__name{
        font-size: 3.5rem;
        font-weight: 500;
    }

    .__img_box{
        /* width: 370px;
        height: 470px; */
        width: fit-content;
        height: fit-content;
    }
    .__img_box img{
    
        object-position: center;
        object-fit:contain;
    }


    /*===================
    ===============about section
    ===================== */

       .about{
 
        padding: 2rem 0rem ;
       }



       #skills{
        padding: 7rem 0 2rem;
       }
       .skills__para{
        width: 100%;
        text-align: justify;
       }
    } 
    
