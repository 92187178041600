.custom__nav__bg{
    opacity: 0.95;
    background-color: var(--main-color);
  
  }
  
  .__btn__primary{
    width: fit-content;
    padding: 0.5rem 1rem;
    /* background-color: var(--btn-bg-color) ; */
    background-color: #84bbff;
    border: none;
    border-radius: 5px;
    color: var(--main-color) !important;
    text-decoration: none;
    text-align: center;
    
    cursor: pointer;
  
  }
  .__btn__primary:hover{
    background-color: var(--btn-bg-hover) !important;
  }
  
  .__btn__outline{
    width: fit-content;
    padding: 0.5rem 1rem;
    /* background-color: var(--btn-bg-color) ; */
    /* background-color: #84bbff; */
    /* border: 1px solid var(); */
    border: 1px solid #84bbff;
    border-radius: 5px;
    /* color: var(--main-color) !important; */
    color: black;
    text-decoration: none;
    text-align: center;
    
    cursor: pointer;
  
  }
  .__btn__outline:hover{
    background-color: var(--btn-bg-hover) !important;
    color: var(--main-color) !important;
    /* fill: var(--main-color); */
  }
  .__btn__outline:hover svg{
    fill: var(--main-color);
  }
  
  
  /* ====================================
  ===============  ICONS ===============
   ==================================== */
  
   .fb-icon:hover{
    fill:#3b5998;
    
   }
   .linkedin-icon:hover{
    fill:#0077B5;
   }
   .x-icon:hover{
    fill:#0077B5;
   }
   .youtube-icon:hover{
    fill:#FF0000;
   }
   .git-lab:hover{
    fill: #fca326;
   }
   .github-icon:hover{
    fill: #000;
   }
  
  
  
  
   .__skills{
    /* padding: 10px; */
  
    /* text-decoration: underline;
    text-underline-offset: 5px; */
    background: var(--main-color);
    color: var(--black-color);
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    padding: 1rem 3rem 1rem 1rem;
    border: 1px solid #dee2e6;
   }
   .__date{
    padding: 5px;
    width: fit-content;
    border-radius: 0.375rem;
    background-color: var(--site-bg-color);
   }
  .top__space{
    margin-top: 4rem;
  }
  .top__space__withbg{
    margin-top: calc(4rem - 1.5rem);
  }
  
   /* .line{
    position: absolute;
    width: 100%;
    border-bottom:1px solid #f76565;
  
   } */
   .seprator__line{
    border-top: 1px solid;
    border-image: linear-gradient(to right, transparent, var(--btn-bg-color), transparent) 1;
   }
   .text-uderline{
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-color: var(--text-underline-color);
    text-decoration-thickness: 1px;
   }

   .social-icon{
    width: 15px;
    height: 15px;
   }


   .contact__deatils{
    background-color: rgba(0, 0, 0, 0.051);
    border-radius: 20px;
    padding: 2px 1rem ;
    width: fit-content;
    text-decoration: none;
   }