#skills{
    padding: 7rem 0 0;
    
   }

   .skills__para{
    width: 75%;
   }


   /* ============================skills accordions start=============================== */
   .accordion {
    /* width: 90%; */
    /* max-width: 1000px;
    margin: 2rem auto; */
  }
  
  .accordion-item {
    background: var(--main-color);
    color: var(--black-color);
    margin: 0.5rem 0;
    border-radius: 0.5rem;
    /* box-shadow: var(--main-shadow); */

    /* box-shadow: 0 2px 5px rgba(0,0,0,0.25); */
  }

  .accordion-item-header {
    padding: 0.5rem 3rem 0.5rem 1rem;
    min-height: 3.5rem;
    line-height: 1.25rem;
    font-weight:600;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }
  
  .accordion-item-header::after {
    content: "\002B";
    font-size: 2rem;
    position: absolute;
    right: 1rem;
  }
  
  .accordion-item-header.active::after {
    content: "\2212";
  }
  
  .accordion-item-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  
  .accordion-item-body-content {
    padding: 1rem;
    line-height: 1.5rem;
    border-top: 1px solid;
    border-image: linear-gradient(to right, transparent, var(--btn-bg-color), transparent) 1;
  }