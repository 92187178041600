
:root{
  /* --site-bg-color:#eeeeeee8; */
  /* --site-bg-color:#FAF9F6; */
  --site-bg-color:rgba(0, 0, 0, 0.05);
  --main-color:#fff;
  /* --btn-bg-color:#405369; */
  --btn-bg-color:#34495e;
  --btn-bg-hover:#3b677d;
  --btn-hover:linear-gradient(to bottom, #82bfcc, #3b677d);
  --black-color:#000000;
  /* --main-shadow:5px 7px 9px 3px rgba(0,0,0,0.17); */
  /* --main-shadow: -1px 1px 5px 4px rgba(184,184,184,0.87); */
  /* --main-shadow: -1px 1px 5px 4px rgba(184,184,184,0.54); */
  --main-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  --text-underline-color:#8f8e8e;
  

}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.App{
  /* background-color: var(--site-bg-color); */
  background: linear-gradient(90deg, rgba(254,254,254,1) 28%, rgba(200,225,255,0.6134103299522935) 100%);
}